@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-regular.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-bold.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-bold.woff')
      format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'NB International Pro';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-pro-light.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-pro-light.woff')
      format('woff');
  font-weight: light;
}

@font-face {
  font-family: 'NB International Mono';
  src:
    url('../assets/fonts/nb-international-pro/nb-international-mono.woff2')
      format('woff2'),
    url('../assets/fonts/nb-international-pro/nb-international-mono.woff')
      format('woff');
  font-weight: normal;
}

*,
html {
  scroll-behavior: smooth !important;
  scroll-margin-top: 7rem;
}

/* @layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
} */

@keyframes point {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes point-reverse {
  0%,
  100% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-point {
  animation: point 1s infinite;
}

.animate-point-reverse {
  animation: point-reverse 1s infinite;
}
